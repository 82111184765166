import Button from "../components/Button";
import { Card, CardContainer } from "../components/Card";
import Section from "../components/Section";

export default function ResellerPage() {
  return <div className="flex flex-row w-full">
    <div className="w-3/4 mr-12 flex flex-col gap-12">
      <Section title="Register an Opportunity">
        <div className="flex flex-col gap-4">
          <textarea
            className="max-w-2xl w-full px-4 py-3 rounded bg-white border-2 border-cyan-500 border-opacity-20 focus:border-opacity-0 outline-none focus:ring-2 focus:ring-cyan-400 focus:shadow-lg focus:shadow-cyan-100"
            placeholder="Enter the opportunity details here"
            rows={6}>
          </textarea>
          <Button size="lg" className="self-start">Register Opportunity</Button>
        </div>
      </Section>
      <Section title="Op Trial Outcome Template">
        <div className="flex flex-col gap-4 animate-pulse">
          <div className="h-4 rounded-full bg-slate-200 w-3/4"/>
          <div className="h-4 rounded-full bg-slate-200 w-full"/>
          <div className="h-4 rounded-full bg-slate-200 w-1/2"/>
          <div className="h-4 rounded-full bg-slate-200 w-2/3"/>
        </div>
      </Section>
      <Section title="Sales Tools &amp; Materials">
        <CardContainer>
          <Card title="Cost Estimator" link="/cost-estimator/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_calculator_re_alsc.svg`} alt="An illustration of a man pressing buttons on a giant calculator" className="h-full max-w-full"/>
          </Card>
          <Card title="Video &amp; Script">
            <img src={`${process.env.PUBLIC_URL}/undraw_video_files_fu10.svg`} alt="An illustation of a stack of paper sheets with a video icon on them" className="h-full max-w-full"/>
          </Card>
        </CardContainer>
      </Section>
    </div>
    <div className="w-1/4">
      <Section title="My Registered Ops">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center animate-pulse">
            <div className="rounded-full h-8 w-8 bg-slate-200 mr-2 flex-shrink-0"/>
            <div className="flex flex-col gap-2 flex-grow">
              <div className="h-2 rounded-full bg-slate-200 w-full"/>
              <div className="h-2 rounded-full bg-slate-200 w-3/4"/>
            </div>
          </div>
          <div className="flex flex-row items-center animate-pulse">
            <div className="rounded-full h-8 w-8 bg-slate-200 mr-2 flex-shrink-0"/>
            <div className="flex flex-col gap-2 flex-grow">
              <div className="h-2 rounded-full bg-slate-200 w-full"/>
              <div className="h-2 rounded-full bg-slate-200 w-3/4"/>
            </div>
          </div>
          <div className="flex flex-row items-center animate-pulse">
            <div className="rounded-full h-8 w-8 bg-slate-200 mr-2 flex-shrink-0"/>
            <div className="flex flex-col gap-2 flex-grow">
              <div className="h-2 rounded-full bg-slate-200 w-full"/>
              <div className="h-2 rounded-full bg-slate-200 w-3/4"/>
            </div>
          </div>
        </div>
      </Section>
    </div>
  </div>
}
