import { Card, CardContainer } from "../components/Card";
import { useRoleCheck, Role } from "../hooks/useRoles";

export default function HomePage() {
  const isAdmin = useRoleCheck(Role.ADMIN)
  const isExec = useRoleCheck(Role.EXEC)
  const isReseller = useRoleCheck(Role.RESELLER)

  return <CardContainer>
    {isAdmin || isExec
      ? <Card title="22 Business Plan" link="/presentation/2022/" external>
        <img src={`${process.env.PUBLIC_URL}/undraw_business_plan_re_0v81.svg`} alt="An illustration of a man giving a presentation on an increasing bar chart" className="h-full max-w-full sepia brightness-75"/>
      </Card>
      : null}
    {isAdmin || isExec
      ? <Card title="23 Business Plan Draft" link="/presentation/2023/" external>
        <img src={`${process.env.PUBLIC_URL}/undraw_business_plan_re_0v81.svg`} alt="An illustration of a man giving a presentation on an increasing bar chart" className="h-full max-w-full"/>
      </Card>
      : null}
    {isAdmin
      ? <Card title="Implementations" link="implementations">
        <img src={`${process.env.PUBLIC_URL}/undraw_select_house_re_s1j9.svg`} alt="An illustration of a man holding two miniature houses" className="h-full max-w-full"/>
      </Card>
      : null}
    {isAdmin || isReseller
      ? <Card title="Reseller Resources" link="reseller">
        <img src={`${process.env.PUBLIC_URL}/undraw_phone_call_re_hx6a.svg`} alt="An illustration of a salesman making a phone call" className="h-full max-w-full"/>
      </Card>
      : null}
    {isAdmin
      ? <Card title="Operations Report" link="/opsreport/" external>
        <img src={`${process.env.PUBLIC_URL}/undraw_at_work_re_qotl.svg`} alt="An illustration of a businesswoman next to two giant printed sheets of paper" className="h-full max-w-full"/>
      </Card>
      : null}
    <Card title="Söze DevOps" link="https://dev.azure.com/ajilonsc/S%C3%B6ze/" external>
      <img src={`${process.env.PUBLIC_URL}/undraw_programmer_re_owql.svg`} alt="An illustration of a woman sitting in front of a screen full of code with a mug in her hand" className="h-full max-w-full"/>
    </Card>
    <Card title="Sales Activity">
      <img src={`${process.env.PUBLIC_URL}/undraw_playful_cat_re_ac9g.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
    </Card>
    <Card title="Financials">
      <img src={`${process.env.PUBLIC_URL}/undraw_playful_cat_re_ac9g.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
    </Card>
    <Card title="Sales Resources">
      <img src={`${process.env.PUBLIC_URL}/undraw_playful_cat_re_ac9g.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
    </Card>
    <Card title="Söze Demo Environment">
      <img src={`${process.env.PUBLIC_URL}/undraw_playful_cat_re_ac9g.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
    </Card>
    <Card title="Söze Training Environment">
      <img src={`${process.env.PUBLIC_URL}/undraw_playful_cat_re_ac9g.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
    </Card>
    <Card title="Söze Training Resources">
      <img src={`${process.env.PUBLIC_URL}/undraw_playful_cat_re_ac9g.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
    </Card>
  </CardContainer>
}
