import { NavLink, To } from "react-router-dom";
import { Role, useRoleCheck } from "../hooks/useRoles";

export default function NavMenu() {
  const isAdmin = useRoleCheck(Role.ADMIN)
  const isReseller = useRoleCheck(Role.RESELLER)

  return <nav className="w-56 flex-shrink-0 border-r border-slate-200">
    <NavItem to="/">Dashboard</NavItem>
    {isAdmin || isReseller ? <NavItem to="/reseller">Reseller Resources</NavItem> : null}
    {isAdmin ? <NavItem to="/implementations">Söze Implementations</NavItem> : null}
  </nav>
}

function NavItem(props: {
  to: To,
  children?: React.ReactNode
}) {
  const activeClassNames = "font-semibold text-cyan-900 border-b-4 border-cyan-400 border-opacity-20"
  const classNames = "text-cyan-600"

  return <div className="mb-2">
    <NavLink {...props} className={({ isActive }) => isActive ? `${classNames} ${activeClassNames}` : classNames} />
  </div>
}
