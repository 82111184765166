import React from "react";
import { Card } from "../components/Card";

export default function ImplementationsPage() {
  return <table className="table-auto w-full">
    <thead>
      <tr className="border-b border-slate-200">
        <th className="p-4 pl-0 text-left uppercase tracking-wide text-sm text-slate-400">Environment</th>
        <th className="p-4 text-left uppercase tracking-wide text-sm text-slate-400">Health</th>
        <th className="p-4 text-left uppercase tracking-wide text-sm text-slate-400">Version</th>
        <th className="p-4 text-left uppercase tracking-wide text-sm text-slate-400">Licence</th>
        <th className="p-4 text-left uppercase tracking-wide text-sm text-slate-400">Incidents</th>
        <th className="p-4 text-left uppercase tracking-wide text-sm text-slate-400">Outcome Reports</th>
        <th className="p-4 pr-0 text-left uppercase tracking-wide text-sm text-slate-400">Contacts</th>
      </tr>
    </thead>
    <tbody>
      <tr className="border-b border-slate-200">
        <td className="p-4 pl-0">
          <Card title="Demo" link="https://soze-demo.azurefd.net/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_business_plan_re_0v81.svg`} alt="An illustration of a man giving a presentation" className="h-full max-w-full"/>
          </Card>
        </td>
        <td className="p-4">
          <div className="h-8 w-8 bg-green-500 rounded-full"/>
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['API', '13.0.6'],
            ['Web', '13.0.4'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Start', '12 July 2021'],
            ['Term', 'Unlimited'],
            ['Expires', 'Never'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Total Tickets', '8'],
            ['Open Tickets', '2'],
            ['Closed Tickets', '6'],
          ]} />
        </td>
        <td className="p-4">
          <div className="rounded h-12 w-12 bg-slate-200"/>
        </td>
        <td className="p-4 pr-0">
          <div className="flex flex-col gap-2">
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
          </div>
        </td>
      </tr>
      <tr className="border-b border-slate-200">
        <td className="p-4 pl-0">
          <Card title="Training" link="https://soze-training.azurefd.net/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_educator_re_s3jk.svg`} alt="An illustration playful cat" className="h-full max-w-full"/>
          </Card>
        </td>
        <td className="p-4">
          <div className="relative h-8 w-8">
            <div className="animate-ping absolute h-6 w-6 inset-1 rounded-full bg-red-600"></div>
            <div className="relative rounded-full h-8 w-8 bg-red-500"></div>
          </div>
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['API', '13.0.2'],
            ['Web', '13.0.14'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Start', '18 August 2021'],
            ['Term', 'Unlimited'],
            ['Expires', 'Never'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Total Tickets', '8'],
            ['Open Tickets', '2'],
            ['Closed Tickets', '6'],
          ]} />
        </td>
        <td className="p-4">
          <div className="rounded h-12 w-12 bg-slate-200"/>
        </td>
        <td className="p-4 pr-0">
          <div className="flex flex-col gap-2">
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
          </div>
        </td>
      </tr>
      <tr className="border-b border-slate-200">
        <td className="p-4 pl-0">
          <Card title="WA Police" link="https://soze-wapolice.azurefd.net/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_security_re_a2rk_yellow.svg`} alt="An illustration of some documents with a padlock on them" className="h-full max-w-full"/>
          </Card>
        </td>
        <td className="p-4">
          <div className="h-8 w-8 bg-green-500 rounded-full"/>
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['API', '13.0.6'],
            ['Web', '13.0.4'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Start', 'March 2020'],
            ['Term', 'Unlimited'],
            ['Expires', 'Never'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Total Tickets', '8'],
            ['Open Tickets', '2'],
            ['Closed Tickets', '6'],
          ]} />
        </td>
        <td className="p-4">
          <div className="rounded h-12 w-12 bg-slate-200"/>
        </td>
        <td className="p-4 pr-0">
          <div className="flex flex-col gap-2">
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
          </div>
        </td>
      </tr>
      <tr className="border-b border-slate-200">
        <td className="p-4 pl-0">
          <Card title="Henson Dev" link="https://soze-dev1.azurefd.net/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_security_re_a2rk_yellow.svg`} alt="An illustration of some documents with a padlock on them" className="h-full max-w-full"/>
          </Card>
        </td>
        <td className="p-4">
          <div className="h-8 w-8 bg-green-500 rounded-full"/>
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['API', '13.0.6'],
            ['Web', '13.0.4'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Start', '2 May 2021'],
            ['Term', 'Unlimited'],
            ['Expires', 'Never'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Total Tickets', '8'],
            ['Open Tickets', '2'],
            ['Closed Tickets', '6'],
          ]} />
        </td>
        <td className="p-4">
          <div className="rounded h-12 w-12 bg-slate-200"/>
        </td>
        <td className="p-4 pr-0">
          <div className="flex flex-col gap-2">
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
          </div>
        </td>
      </tr>
      <tr className="border-b border-slate-200">
        <td className="p-4 pl-0">
          <Card title="Henson Prod" link="https://soze-prd01.azurefd.net/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_security_re_a2rk_yellow.svg`} alt="An illustration of some documents with a padlock on them" className="h-full max-w-full"/>
          </Card>
        </td>
        <td className="p-4">
          <div className="h-8 w-8 bg-green-500 rounded-full"/>
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['API', '13.0.6'],
            ['Web', '13.0.4'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Start', '2 May 2021'],
            ['Term', 'Unlimited'],
            ['Expires', 'Never'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Total Tickets', '8'],
            ['Open Tickets', '2'],
            ['Closed Tickets', '6'],
          ]} />
        </td>
        <td className="p-4">
          <div className="rounded h-12 w-12 bg-slate-200"/>
        </td>
        <td className="p-4 pr-0">
          <div className="flex flex-col gap-2">
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
          </div>
        </td>
      </tr>
      <tr className="border-b border-slate-200">
        <td className="p-4 pl-0">
          <Card title="Beacon Red" link="https://soze-br.azurefd.net/" external>
            <img src={`${process.env.PUBLIC_URL}/undraw_security_re_a2rk_yellow.svg`} alt="An illustration of some documents with a padlock on them" className="h-full max-w-full"/>
          </Card>
        </td>
        <td className="p-4">
          <div className="h-8 w-8 bg-green-500 rounded-full"/>
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['API', '13.0.6'],
            ['Web', '13.0.4'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Start', '22 Dec 2022'],
            ['Term', 'Unlimited'],
            ['Expires', 'Never'],
          ]} />
        </td>
        <td className="p-4">
          <DictionaryList items={[
            ['Total Tickets', '8'],
            ['Open Tickets', '2'],
            ['Closed Tickets', '6'],
          ]} />
        </td>
        <td className="p-4">
          <div className="rounded h-12 w-12 bg-slate-200"/>
        </td>
        <td className="p-4 pr-0">
          <div className="flex flex-col gap-2">
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
            <div className="rounded-full h-3 w-full bg-slate-200"/>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
}

function DictionaryList(props: { items: [React.ReactNode, React.ReactNode][] }) {
  return <dl>
    {props.items.map((item, i) => <React.Fragment key={i}>
      <dt className="block uppercase font-bold tracking-wider text-slate-400 text-xs mt-4 first:mt-0">
        {item[0]}
      </dt>
      <dd className="block text-lg">
        {item[1]}
      </dd>
    </React.Fragment>)}
  </dl>
}
