import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import './App.css';
import Button from './components/Button';
import NavMenu from './components/NavMenu';
import { Role, useRoleCheck, useRoles } from './hooks/useRoles';
import HomePage from './pages/HomePage';
import ImplementationsPage from './pages/ImplementationsPage';
import ResellerPage from './pages/ResellerPage';

function App() {
  const { logout, isAuthenticated, isLoading, user, loginWithRedirect, error } = useAuth0()
  const isAdmin = useRoleCheck(Role.ADMIN)
  const isReseller = useRoleCheck(Role.RESELLER)
  const { rolesLoading } = useRoles()

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect()
    }
    if (error) {
      console.error(error)
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, error])

  if (!isAuthenticated) {
    return <div className="w-screen h-screen flex items-center justify-center text-lg">
      {(isLoading || rolesLoading) && !error
        ? <span>Securing your session&hellip;</span>
        : <div className="flex flex-col items-center justify-center gap-4">
          <span>Sorry, you don&rsquo;t have access to this page.</span>
          {!!error
            ? <div className="px-4 py-3 bg-red-100 border border-red-400 rounded text-red-700 max-w-3xl">
              {error.name}: {error.message}
            </div>
            : null}
        </div>}
    </div>
  }

  return <div className="min-h-screen bg-slate-50 text-slate-800">
    <div className="border-b border-slate-200 py-4 bg-white">
      <div className="container mx-auto flex items-center">
        <Link to="/" className="mr-auto text-xl font-semibold flex flex-row">
          <img src={`${process.env.PUBLIC_URL}/soze-icon-vector.svg`} alt="Söze logo" className="h-8 mr-2"/>
          Söze Portal
        </Link>
        {isAuthenticated
          ? <React.Fragment>
            <span className="text-base">{user?.name}</span>
            <div className="w-4"> </div>
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
              className="text-cyan-600 underline text-sm">
              Sign out
            </button>
          </React.Fragment>
          : <SignInButton/> }
      </div>
    </div>
    <div className="container mx-auto py-8 flex flex-row gap-12">
      {isAuthenticated
        ? <React.Fragment>
          <NavMenu/>
          <Routes>
            <Route index element={<HomePage/>}/>
            {isAdmin || isReseller ? <Route path="reseller" element={<ResellerPage/>}/> : null}
            {isAdmin ? <Route path="implementations" element={<ImplementationsPage/>}/> : null}
          </Routes>
        </React.Fragment>
        : <div className="flex flex-col items-center w-full">
          <img src={`${process.env.PUBLIC_URL}/undraw_login_re_4vu2.svg`} alt="An illustration of a man in front of a big door" className="mx-auto mt-24 w-96" />
          <div className="mt-6 text-xl">
            Please <SignInButton className="mx-1"/> to continue
          </div>
        </div>}
    </div>
  </div>

}

function SignInButton(props: { className?: string }) {
  const { loginWithRedirect, isLoading } = useAuth0()

  return <Button
    onClick={() => !isLoading && loginWithRedirect()}
    size="sm"
    disabled={isLoading}
    className={props.className}>
    {isLoading ? "Please wait" : "Sign in"}
  </Button>
}

export default App;
