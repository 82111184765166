import { Link } from "react-router-dom"

export function CardContainer(props: { children?: React.ReactNode, vertical?: boolean, className?: string }) {
  return <div className={`flex ${props.vertical ? 'flex-col' : 'flex-row'} flex-wrap gap-8 ${props.className ?? ''}`}>
    {props.children}
  </div>
}

export function Card(props: {
  title?: React.ReactNode,
  children?: React.ReactNode,
  className?: string,
  link?: string
  external?: boolean
}) {
  const Linker = props.external
    ? (p: any) => <a href={props.link} target="_blank" rel="noopener noreferrer" {...p}>{p.children ?? ''}</a>
    : (p: any) => <Link to={props.link ?? ''} {...p}/>

  return <Linker
    className={`h-48 w-64 p-4 flex flex-col border rounded-lg ${props.link ? 'border-slate-200 bg-white hover:border-cyan-300 hover:shadow-cyan-100 cursor-pointer shadow-lg shadow-slate-200' : 'border-slate-300 bg-slate-200 cursor-default'} ${props.className ?? ''}`}>
    <div className="h-4"></div>
    <div className="mx-auto my-auto h-3/4">{props.children}</div>
    <div className="h-4"></div>
    <div className="font-light text-lg mt-2">{props.title}</div>
  </Linker>
}
