export default function Button(props: {
  children?: React.ReactNode,
  disabled?: boolean
  className?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}) {
  const sizeClasses = (() => {
    switch (props.size) {
      case 'xs': return 'text-xs px-2 py-0 font-bold tracking-wider'
      case 'sm': return 'text-sm px-3 py-1 font-bold tracking-wider'
      case 'md':
      default: return 'text-base px-4 py-2 font-bold tracking-wide'
      case 'lg': return 'text-lg px-4 py-2 font-semibold'
      case 'xl': return 'text-xl px-5 py-3 font-semibold'
    }
  })()
  return <button
    onClick={props.onClick}
    disabled={props.disabled}
    className={`rounded ${props.disabled ? 'bg-gray-400' : 'bg-cyan-500 hover:bg-cyan-400 shadow shadow-cyan-200 hover:shadow-lg hover:shadow-cyan-200'} text-white ${sizeClasses} uppercase ${props.className ?? ''}`}>
    {props.children}
  </button>
}
