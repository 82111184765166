import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import jwt_decode from "jwt-decode"

export function useRoles() {
  const [roles, setRoles] = useState<Role[]>()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) {
      setRoles(undefined)
      return;
    }

    getAccessTokenSilently().then(accessToken => {
      const decodedAccessToken: AccessToken = jwt_decode(accessToken)
      const decodedRoles = decodedAccessToken["https://soze.io/claims/roles"]
      setRoles(decodedRoles)
    })
  }, [isAuthenticated, getAccessTokenSilently])

  return { roles, rolesLoading: roles === undefined }
}

export function useRoleCheck(role: Role) {
  const { roles } = useRoles()
  return roles?.includes(role)
}

interface AccessToken {
  "https://soze.io/claims/roles": Role[],
  "https://soze.io/claims/email": string,
  "https://soze.io/claims/name": string,
  "iss": string,
  "sub": string,
  "aud": string[],
  "iat": number,
  "exp": number,
  "azp": string,
  "scope": string,
  "permissions": string[]
}

export enum Role {
  ADMIN = 'admin',
  EXEC = 'executive',
  RESELLER = 'reseller',
  TRAINER = 'trainer'
}
